import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';
import HeroArea from '../components/HeroArea';
import Card from '../images/hvac-financing-minnesota.jpg';
import ImageBanner from '../images/hvac-financing-mn.jpg';
import { StaticImage } from 'gatsby-plugin-image';
const Financing = (props) => {
  return (
    <Layout bodyClass="page-financing">
      <SEO
        title="Minnesota HVAC Financing With LaSalle"
        description="Minneseota HVAC Financing"
      />
      <section className="grid items-center grid-cols-1 md:grid-cols-2 max-w-screen-xl mx-auto">
        <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-26 lg:text-left">
          <div className="px-4  sm:px-8 xl:pr-16">
            <h1 className="prose font-bold text-5xl">
              <span className="block">HVAC Financing</span>
              <span className="block text-orange-600 clear-both">
                From Wells Fargo
              </span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              Client Testimonial:
            </p>
            <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              "During the heatwave (Week of June 7th) when no other company
              would and I bet I called over 20! They made time for me! My air
              conditioner died and it reached over 100 in the house. They made
              every effort to fix my air, even calling the manufacturer. It was
              not meant to be.{' '}
              <strong>
                They were able replace my air the same week and had a great
                finance option!
              </strong>{' '}
              The tech's were great!""
            </p>
            <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <Link
                  href="/hvac-service-installation-repair-scheduling"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700 md:py-4 md:text-lg md:px-10"
                >
                  Schedule Service
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div>
          <StaticImage
            src="../images/ minnesota-hvac-financing.jpg"
            alt="A kitten"
            className=" inset-0"
          />
        </div>
      </section>
      <div className="px-4 md:px-0">
        <div className="max-w-prose mx-auto py-20">
          <p>
            One of the most essential and important investments made as a home
            owner is the purchase and maintenance of your HVAC system. If you
            ever need repairs, or decide it’s time to replace your system, we’ll
            help you find the right product that fits your budget. As your local
            Bryant Factory Authorized Dealer, not only do we provide high
            quality reliable heating and cooling solutions- we make these
            solutions affordable to all our customers.
          </p>
        </div>
        <div className="container22 pb-8 financing-banner prose mx-auto">
          <p>
            Buy today, pay over time with the Bryant® credit card, issued with
            approved credit by Wells Fargo Bank, N.A. an Equal Housing Lender.
          </p>
          <p>With approved credit from Wells Fargo, you’ll enjoy:</p>

          <ul>
            <li>Convenient monthly payments</li>
            <li>Flexible financing options</li>
            <li>Easy online account management</li>
          </ul>

          <p>
            Plus, by taking advantage of financing, you won’t use up your
            existing funding sources, like a home equity line of credit, other
            credit card or bank account.  They’ll still be available when you
            need them.
          </p>

          <p>
            Please contact us to find out which financing options are available
            to you, or to learn about special financing promotions. Click the
            image below to apply online.
          </p>
          <center>
            <a
              href="https://retailservices.wellsfargo.com/pl/0030081822"
              target="_blank"
            >
              <img src={ImageBanner} className="finance-img" />
            </a>
            <a
              href="https://retailservices.wellsfargo.com/pl/0030081822"
              target="_blank"
            >
              <img src={Card} className="card-img" />
            </a>
          </center>
        </div>
      </div>
    </Layout>
  );
};

export default Financing;
